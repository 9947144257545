import businessRouter from './businessRouter';//二维码系统路由
import qrcodeRouter from './qrcodeRouter';//二维码系统路由
import financialRouter from './financialRouter';//财务中心路由
import balanceRouter from './asset/balanceRouter';//首页路由
import projectRouter from './projectRouter';//首页路由
import datacenterRouter from './datacenterRouter';// 大数据中心路由
import scrmuserRouter from './scrmuserRouter';// SCRM用户中心路由
import qudaobaoRouter from './qudaobaoRouter';// 渠道宝系统路由
import qudaoRouter from './qudaoRouter';// 3.0渠道系统路由
import fileRouter from './fileRouter';// 报表文件系统
import playCardRouter from './playCardRouter';// 集卡玩法
import integrateRouter from './integrateRouter';// 积分系统路由
/**
 * 主业务
 */
const configRouterSetUp = [
    {
        path: '/home',
        component: resolve => require(['../../components/common/Home.vue'], resolve),
        meta: {title: '自述文件'},
        children: [
            ...businessRouter,
            ...qrcodeRouter,
            ...financialRouter,
            ...balanceRouter,
            ...projectRouter,
            ...datacenterRouter,
            ...scrmuserRouter,
            ...qudaobaoRouter,
            ...qudaoRouter,
            ...fileRouter,
			...integrateRouter,
            ...playCardRouter
        ]
    },
]
export default configRouterSetUp